.our-cars-header {
	padding: 64px 64px 32px;
	border-bottom: 1px solid var(--grey);
	position: relative;
	z-index: 5;
}
.our-cars-header h1 {
	font-weight: 700;
	font-size: 64px;
	line-height: 76px;
	margin-bottom: 40px;
}
@media (max-width: 991.4px) {
	.our-cars-header {
		padding: 48px 16px 24px;
	}
	.our-cars-header h1 {
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 24px;
	}
}
