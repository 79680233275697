
.slider-mobile .swiper-pagination {
	display: flex;
	justify-content: space-between;
	bottom: 8px !important;
}

.slider-mobile .swiper-pagination-bullet {
	position: relative;
	height: 2px !important;
	flex-grow: 1;
	border-radius: 0 !important;
	background-color: transparent !important;
	opacity: 1 !important;
}

.slider-mobile .swiper-pagination-bullet::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	opacity: 0.5;
	transition-duration: 0.2s;
	padding-left: 4px;
	padding-right: 4px;
}

.slider-mobile .swiper-pagination-bullet-active::before{
	opacity: 1;
}

@media (max-width: 450px) {
	.slider-mobile .slider__pics-count {
		font-size: 14px;
		line-height: 18px;
		margin-top: 18px;
	}
	.slider-mobile .slider__much-pics-info svg {
		width: 53px;
		height: 48px;
	}
}