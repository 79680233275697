.car-card {
	display: flex;
	align-items: stretch;
	gap: 32px;
	padding: 32px 64px;
	border-bottom: 1px solid var(--grey);
	cursor: pointer;
}

.car-card:hover .car-card__model {
	color: #00ac6e !important;
	transition-duration: 0.4s;
}

.car-card__model {
	transition-duration: 0.4s;
}

.car-card__line {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	width: 50%;
}

.slider {
	width: 40%;
	min-width: 40%;
}

.car-card .slider::before {
	content: "";
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
	padding-top: calc(340 / 528 * 100%);
}

.car-card__info {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.car-card__description {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-between;
	margin-top: 32px;
	flex-grow: 1;
}

.car-card__header {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.03em;
	color: #6d6d6d;
	width: 104px;
	/* margin-right: 32px; */
}

.car-card__table-data {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.03em;
}

.car-card__table-data span {
	padding-right: 9px;
}

.car-card__kilometers span {
	padding: 0 !important;
}

.car-card__table-data span {
	white-space: nowrap;
}

.car-card__bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.car-card__bottom h3 {
	white-space: nowrap;
}

.span-with-before {
	padding-left: 9px;
	position: relative;
}

.span-with-before:last-child {
	padding-right: 0;
}

.span-with-before::before {
	content: "";
	height: 17px;
	width: 1px;
	background-color: #222222;
	position: absolute;
	left: 0;
	top: 0;
}

.car-card__drive-unit {
	order: 5;
}

.car-card__transmission {
	order: 3;
}

.car-card__engine {
	order: 1;
}

.car-card__owners {
	order: 4;
}

.car-card__mileage {
	order: 2;
}

.car-card__year {
	order: 0;
}

.car-card__owners,
.car-card__mileage,
.car-card__year {
	width: 37.2%;
}

.car-card__drive-unit,
.car-card__transmission,
.car-card__engine {
	width: 56.3%;
}

.car-card__table-data span:last-child,
.car-card__table-data span:only-child {
	padding-right: 0 !important;
}

.car-card .slider-mobile {
	display: none;
	width: 100%;
}
.car-card__slider-mobile-wrap .slider-mobile {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.car-card__slider-mobile-wrap  {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: calc(160 / 288 * 100%);
	display: none;
}

@media (min-width: 1600px) {
	.car-card__model,
	.car-card__bottom h3 {
		font-size: 32px;
		line-height: 40px;
	}

	.car-card__drive-unit {
		order: 5;
		width: 27.4% !important;
		min-width: 27.4% !important;
	}

	.car-card__transmission {
		order: 2;
		width: 27.4% !important;
		min-width: 27.4% !important;
	}

	.car-card__drive-unit .car-card__header,
	.car-card__transmission .car-card__header {
		width: 110px;
		min-width: 110px;
	}

	.car-card__engine {
		order: 4;
		width: 33% !important;
		min-width: 319px !important;
	}

	.car-card__owners {
		order: 1;
		width: 33% !important;
		min-width: 319px !important;
	}

	.car-card__engine .car-card__header,
	.car-card__owners .car-card__header {
		width: 130px;
		min-width: 130px;
	}

	.car-card__mileage {
		order: 3;
		width: 21.5% !important;
		min-width: 21.5% !important;
	}

	.slider {
		max-width: 469px !important;
		width: 469px !important;
		min-width: 469px;
		height: 302px;
	}

	.car-card__info {
		flex-grow: 1;
	}

	.car-card__year {
		order: 0;
		width: 21.5% !important;
		min-width: 21.5% !important;
	}

	.car-card__year .car-card__header,
	.car-card__mileage .car-card__header {
		width: 99px;
		min-width: 99px;
	}
}

@media (max-width: 991.4px) {
	.car-card {
		padding: 24px 16px;
		flex-direction: column;
	}

	.slider {
		display: none !important;
	}

	.car-card__bottom h3,
	.car-card__model {
		font-size: 24px;
		line-height: 28px;
	}

	.car-card .slider-mobile, .car-card__slider-mobile-wrap {
		display: block;
	}
}

@media (max-width: 744px) {
	.car-card {
		padding: 24px 16px;
		flex-direction: column;
	}

	.slider {
		width: 100%;
	}
}

@media (min-width: 576px) and (max-width: 1600px) {
	.car-card__header {
		width: 114px;
		margin-right: 0;
	}
}

@media (max-width: 600px) {
	.car-card__drive-unit {
		order: 5;
	}

	.car-card__transmission {
		order: 4;
	}

	.car-card__engine {
		order: 3;
	}

	.car-card__owners {
		order: 2;
	}

	.car-card__mileage {
		order: 1;
	}

	.car-card__year {
		order: 0;
	}

	.car-card {
		gap: 16px;
		padding: 24px 16px;
	}

	.car-card__bottom .button {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.car-card__line {
		width: 100%;
		justify-content: space-between;
	}

	.car-card__header {
		width: 88px;
	}

	.car-card__slider {
		width: 100%;
	}

	.car-card__bottom {
		display: block;
	}

	.car-card__bottom h3 {
		margin: 16px 0 12px;
	}

	.slider {
		width: 100%;
	}

	.car-card__line {
		margin-bottom: 8px;
	}

	.car-card__description {
		margin-top: 16px;
	}

	.car-card__header,
	.car-card__table-data {
		font-size: 16px;
		line-height: 20px;
	}
}

@media (max-width: 400px) {
	.car-card__header {
		margin-right: 16px !important;
	}
}

@media (min-width: 576px) and (max-width: 991px) {
	.car-card__drive-unit,
	.car-card__transmission,
	.car-card__engine {
		min-width: 320px;
	}
}

@media (min-width: 991.4px) and (max-width: 1130px) {
	.car-card__header {
		margin-right: 16px;
	}

	.car-card__owners,
	.car-card__mileage,
	.car-card__year {
		width: 34%;
	}

	.car-card__drive-unit,
	.car-card__transmission,
	.car-card__engine {
		width: 58%;
	}

	.car-card__header {
		margin-right: 0 !important;
		width: 100px;
		min-width: 100px;
	}
}
