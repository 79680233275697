.contacts {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	padding: 64px;
}
.contacts__info {
	width: 40%;
}
.contacts__info h1 {
	font-weight: 700;
	font-size: 64px;
	line-height: 76px;
	padding-bottom: 8px;
}
.contacts__phone {
	padding-bottom: 24px;
}
.contacts__header {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: #6d6d6d;
	text-transform: uppercase;
}
.contacts__sub-header {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #6d6d6d;
	margin-bottom: 8px;
}
.contacts__data,
.contacts__data a {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #222222;
	display: block;
}
.contacts__info-row {
	display: flex;
	align-items: flex-start;
	padding: 32px 0;
	border-bottom: 1px solid var(--grey);
}
.contacts__data,
.contacts__header {
	width: 50%;
}
.contacts__info-row:last-child {
	padding-bottom: 0;
	border-bottom: none;
}
.contacts__data-socials {
	padding-bottom: 32px;
}
.contacts__data-socials:last-child {
	padding-bottom: 0;
}
.contacts__map {
	width: 55%;
	min-width: 55%;
}
@media (max-width: 1068px) {
	.contacts__info h1 {
		font-size: 24px;
		line-height: 28px;
		padding-bottom: 4px;
	}
	.contacts__info-row {
		padding: 20px 0;
		flex-direction: column;
	}
	.contacts__data,
	.contacts__header,
	.contacts__data a {
		width: 100%;
		font-size: 16px !important;
		line-height: 20px !important;
	}
	.contacts__header {
		margin-bottom: 16px;
	}
	.contacts__sub-header {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 4px;
	}
	.contacts__phone {
		padding-bottom: 12px;
	}
	.contacts__data-socials {
		padding-bottom: 22px;
	}
}
.contacts__map-wrap {
	width: 100%;
	height: 100%;
}
@media (max-width: 991.4px) {
	.contacts {
		display: block;
		padding: 48px 16px 80px;
	}
	.contacts__info {
		width: 100%;
	}
	.contacts__map {
		width: 100%;
		margin-top: 32px;
		position: relative;
		height: 0;
		padding-bottom: calc(240 / 288 * 100%);
	}
	.contacts__map-wrap {
		position: absolute;
		top: 0;
		left: 0;
	}
}
