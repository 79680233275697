.slider {
	position: relative;
	display: flex;
	background-color: gray;
	cursor: pointer;
}

.slider__page {
	/* width: 19.39%; */
	width: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	--op: 0.5;
}

.slider__page:hover {
	--op: 1;
}

.slider__page:hover .slider__image {
	display: block;
}

.slider__page:first-child,
.slider__page:last-child {
	/* width: 20.915%; */
}

.slider__image {
	top: 0;
	left: 0;
	width: 100%;
	position: absolute;
	height: 100%;
	z-index: 2;
	display: none;
}

.slider__image-bg {
	top: 0;
	left: 0;
	width: 100%;
	position: absolute;
	height: 100%;
	z-index: 1;
}

.slider__image img,
.slider__image-bg img {
	height: 100%;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.slider__btn {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 3;
}

.slider__btn:hover {}

.slider__btn::before {
	content: '';
	position: absolute;
	bottom: 12px;
	left: 4px;
	height: 2px;
	width: calc(100% - 8px);
	transition-duration: 0.2s;
	background-color: #ffffff;
	opacity: var(--op);
}

.slider__page:first-child .slider__btn::before {
	left: 12px;
	width: calc(100% - 16px);
}

.slider__page:last-child .slider__btn::before {
	left: 4px;
	width: calc(100% - 16px);
}

.slider__much-pics {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider__much-pics-info {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slider__pics-count {
	margin-top: 28px;
	font-weight: 500;
	font-size: 18px;
	line-height: calc(24/18);
	color:#ffffff;
	text-transform: uppercase;
	letter-spacing: 0.03em;
}