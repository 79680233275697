.contacts__map-widget {
	width: 100%;
	height: 100%;
	position: relative;
}

.ymaps-2-1-79-map {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.ymaps-2-1-79-map canvas {
	/* position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important; */
}

/* .ymaps-2-1-79-ground-pane {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
} */

/* .ymaps-2-1-79-ground-pane ymaps {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
} */

/* .ymaps-2-1-79-ground-pane canvas,
.ymaps-2-1-79-places-pane {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
} */

.ymaps-2-1-79-ground-pane canvas{
	mix-blend-mode: color;
	background-color: grey;
}

.ymaps-2-1-79-ground-pane {
	filter: grayscale(1);
	-ms-filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
}

.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright,
.ymaps-2-1-79-copyright__wrap,
.ymaps-2-1-79-copyright__content {
	display: none !important;
}

/* .ymaps-2-1-79-placemark-overlay {
	top: 50% !important;
	left: 50% !important;
} */



.ymaps-2-1-79-map .ymaps-2-1-79-inner-panes .ymaps-2-1-79-places-pane .ymaps-2-1-79-placemark__r {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA0MCA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMCA1NkMyMCA1NiA0MCAzMS4wNDU3IDQwIDIwQzQwIDguOTU0MyAzMS4wNDU3IDAgMjAgMEM4Ljk1NDMgMCAwIDguOTU0MyAwIDIwQzAgMzEuMDQ1NyAyMCA1NiAyMCA1NlpNMjAgMzBDMjUuNTIyOCAzMCAzMCAyNS41MjI4IDMwIDIwQzMwIDE0LjQ3NzIgMjUuNTIyOCAxMCAyMCAxMEMxNC40NzcyIDEwIDEwIDE0LjQ3NzIgMTAgMjBDMTAgMjUuNTIyOCAxNC40NzcyIDMwIDIwIDMwWiIgZmlsbD0iIzAwQUM2RSIvPgo8L3N2Zz4K') !important;
	width: 40px !important;
	height: 56px !important;
	background-position: 0px 0px !important;
	right: -14px !important;
}