.our-cars-show-more {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 28px 64px;
	margin-bottom: 64px;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--grey);
}
.our-cars-show-more:hover h5 {
	color: #00AC6E;
	transition-duration: 0.4s;
}
.our-cars-show-more:hover svg, .our-cars-show-more:hover path{ 
	fill: #00AC6E;
	transition-duration: 0.4s;
}

.our-cars-show-more h5 {
	transition-duration: 0.4s;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.03em;
	text-transform: none;
}
.our-cars-show-more__icon svg, .our-cars-show-more__icon path {
	width: 32px;
	height: 32px;
	transition-duration: 0.4s;
}

@media (max-width: 991.4px) {
	.our-cars-show-more {
		padding: 18px 16px;
		margin-bottom: 32px;
	}
	.our-cars-show-more__icon svg {
		height: 24px;
		width: 24px;
	}
	.our-cars-show-more h5 {
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
	}
	.our-cars-show-more__icon svg {
		height: 24px;
		width: 24px;
	}
}

