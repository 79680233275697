.skeleton-item {
	width: 100%;
	height: 100%;
	transition-duration: 0.4s;
	background-color: #ebebeb;
	animation: change-color 1.2s ease-in-out infinite;
	border-radius: 2px;
}

@keyframes change-color {
	0%,
	100% {
		background-color: #ebebeb;
	}

	50% {
		background-color: #bdbdbd;
	}
}

.skeleton-slider {
	width: 40%;
	min-width: 40%;
}
.skeleton-slider::before {
	content: "";
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
	padding-top: calc(340 / 528 * 100%);
}

.skeleton-car-card .car-card__model {
	width: 540px;
	height: 40px;
	max-width: 80%;
}

.skeleton-car-card .car-card__line {
	height: 22px;
}

.skeleton-car-card .car-card__description {
	flex-direction: column;
}

@media (min-width: 1600px) {
	.skeleton-slider {
		max-width: 469px !important;
		width: 469px !important;
		min-width: 469px;
		height: 302px;
	}

	.skeleton-car-card .car-card__info {
		max-width: calc(100% - 501px);
	}

	.skeleton-car-card .car-card__line {
		width: calc(100% / 3 - 64px / 3) !important;
	}

	.skeleton-car-card .car-card__description,
	.skeleton-car-card .car-card__bottom {
		width: auto !important;
		max-width: 100% !important;
	}
}

.skeleton-car-card .car-card__line {
	width: 100%;
}

.skeleton-car-card-footer1 {
	width: 106px;
	height: 24px;
	margin-bottom: 12px;
}

.skeleton-car-card-footer2 {
	width: 100%;
	height: 40px;
}

.skeleton-car-card .car-card__bottom {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (min-width: 992px) {
	.skeleton-car-card .car-card__description {
		width: 752px;
		max-width: 90%;
		justify-content: space-between;
		flex-direction: row;
	}

	.skeleton-car-card .car-card__line {
		width: calc(50% - 24px);
	}

	.skeleton-car-card .car-card__bottom {
		width: 752px;
		max-width: 90%;
		flex-direction: row;
		justify-content: space-between;
	}

	.skeleton-car-card-footer1 {
		width: 160px;
		height: 40px;
		margin-bottom: 0;
	}

	.skeleton-car-card-footer2 {
		width: 160px;
		height: 48px;
	}

	.skeleton-car-card .car-card__info {
		max-width: calc(60% - 32px);
	}
}

@media (max-width: 991.4px) {
	.skeleton-slider {
		width: 100%;
		min-width: 100%;
		height: 0;
		padding-top: calc(160 / 288 * 100%);
		position: relative;
	}
	.skeleton-slider .skeleton-item {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	/* .skeleton-slider::before {
		padding-top: calc(160 / 288 * 100%);
	} */

	.skeleton-car-card .car-card__model {
		width: 100%;
		height: 22px;
	}

	.skeleton-car-card .car-card__line {
		height: 16px;
	}
}
