.droplist {
	border-top: 1px solid var(--grey);
}

.droplist:last-child {
	border-bottom: 1px solid var(--grey);
}

.droplist__head {
	cursor: pointer;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.droplist__name {
	font-weight: 500;
	text-transform: uppercase;
}

.droplist__icon {
	width: 24px;
	height: 24px;
	position: relative;
}

.droplist__icon svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition-duration: 0.4s;
}

.droplist__plus {
	opacity: 1;
}
.droplist__minus {
	opacity: 0;
}

.droplist.active .droplist__plus {
	opacity: 0;
}

.droplist.active .droplist__minus {
	opacity: 1;
}

.droplist__body {
	display: none;
}

.droplist.active .droplist__body {
	display: block;
}

@media (max-width: 991.4px) {
	.droplist {
		font-size: 16px;
		line-height: 20px;
	}
}
