@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_regular.otf);
	font-weight: normal;
}

@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_bold.otf);
	font-weight: 700;
}

@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_medium.otf);
	font-weight: 600;
}

@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_ultrabold.otf);
	font-weight: 900;
}

@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_light.otf);
	font-weight: 300;
}

@font-face {
	font-family: 'GTeestIProDisplay';
	src: url(/fonts/gteestiprodisplay_thin.otf);
	font-weight: 200;
}

body {
	margin: 0;
	font-family: 'GTeestIProDisplay', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--body-color: #222222;
	color: var(--body-color);
	font-size: 18px;
	line-height: calc(24/18);
	--primary: #008b59;
	--grey: #c7c7c7;
}

.scrollbar-horizontal::-webkit-scrollbar {
	height: 7px;
}

.scrollbar-horizontal::-webkit-scrollbar-track {
	background-color: transparent;
}

.scrollbar-horizontal::-webkit-scrollbar-thumb {
	background-color: #b6b7bf;
	/* цвет плашки */
	border-radius: 16px;
	/* закругления плашки */
}

.scrollbar-vertical::-webkit-scrollbar {
	width: 7px;
}

.scrollbar-vertical::-webkit-scrollbar-track {
	background-color: transparent;
}

.scrollbar-vertical::-webkit-scrollbar-thumb {
	background-color: #b6b7bf;
	/* цвет плашки */
	border-radius: 16px;
	/* закругления плашки */
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
	color: #000000;
	text-transform: uppercase;
	margin-bottom: 0;
}

a {
	text-decoration: none;
}

h1 {
	font-size: 84px;
	line-height: calc(88/84);
}

h2 {
	font-size: 64px;
	line-height: calc(76/64);
}

h3 {
	font-size: 32px;
	line-height: calc(40/32);
}

.fixed {
	overflow: hidden;
	height: calc(var(--vh,1vh)*100);
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.order-6 {
	order: 6 !important;
}

@media(min-width:992px) {
	.order-lg-6 {
		order: 6 !important;
	}
}

@media (max-width:991.9px) {
	h1 {
		font-size: 60px;
	}

	h2 {
		font-size: 40px;
	}

	h3 {
		font-size: 24px;
	}

	.fixed-menu {
		overflow: hidden;
		height: calc(var(--vh,1vh)*100);
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
	}

	.fixed-menu .header {
		height: 100vh;
	}
}

@media (max-width:600px) {
	h1 {
		font-size: 50px;
	}

	h2 {
		font-size: 36px;
	}
}

@media (max-width:400px) {
	h1 {
		font-size: 36px;
	}

	h2 {
		font-size: 30px;
	}
}