.why-we {
	padding: 48px 16px;
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
}

.why-we__caption-block {
	width: 100%;
	padding-bottom: 24px;
}

.why-we__caption,
.why-we__list-item-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.why-we__caption-block h2 {
	margin-bottom: 24px;
	font-size: 64px;
}

.why-we__list-item {
	display: flex;
	align-items: flex-start;
	padding-top: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--grey);
}

.why-we__list-item:first-child {
	border-top: 1px solid var(--grey);
}

.why-we__list-item-number {
	font-weight: 700;
	font-size: 36px;
	line-height: 40px;
	color: #6d6d6d;
	text-transform: uppercase;
	margin-right: 24px;
	width: 48px;
	min-width: 48px;
	position: relative;
	top: -5px;
	height: 56px;
}

@media (min-width: 1200px) {
	.why-we__caption-block {
		padding-right: 112px;
	}

	.why-we__list-item-number {
		margin-right: 64px;
	}
}

@media (min-width: 992px) {
	.why-we {
		padding: 64px;
		margin-bottom: 64px;
		flex-direction: row;
		justify-content: space-between;
	}

	.why-we__caption-block {
		width: 51%;
		padding-right: 52px;
		padding-bottom: 0;
	}

	.why-we__caption-block,
	.why-we__list {
		width: calc(50% - 16px);
	}

	.why-we__caption-block h2 {
		margin-bottom: 40px;
	}
	/* .why-we__list-item-number {
		font-size: 64px;
		line-height: 76px;
		margin-right: 48px;
		width: 70px;
		min-width: 70px;
	} */
}

@media (max-width: 1200px) and (min-width: 992px) {
	.why-we__caption-block h2 {
		font-size: 48px;
	}
}

@media (max-width: 991.4px) {
	.why-we__caption-block h2 {
		font-size: 32px;
		line-height: 36px;
	}
}

@media (min-width: 600.4px) {
	.why-we__list-item {
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.why-we__list-item-number {
		font-size: 64px;
		line-height: 76px;
		margin-right: 48px;
		width: 70px;
		min-width: 70px;
	}
	.why-we__caption,
	.why-we__list-item-text {
		font-size: 18px;
		line-height: 24px;
	}
}
