.footer {
	margin-top: auto;
	border-top: 1px solid var(--grey);
	padding: 32px 16px;
	background-color: transparent;
	display: flex;
	flex-direction: column;
}

.footer__logo-block {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 32px;
}

.footer__logo img {
	width: 168px;
	height: 32px;
}

.footer__copyright {
	font-weight: 300;
	font-size: 14px;
	line-height: calc(16/14);
}

.footer__link-block {
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
}

.footer__soc-block {
	display: flex;
	flex-direction: row;
	margin-top: 32px;
}

.footer__soc-block>*:last-child {
	margin-bottom: 0;
}

.footer__info-block>* {
	margin-bottom: 32px;
}

.footer__info-block>*:last-child {
	margin-bottom: 0;
}

.footer__info-block {
	display: flex;
	flex-direction: column;
}

.footer a {
	color: var(--body-color) !important;
}

.copyright {
	display: flex;
	align-items: center;
}

.copyright .text-logo {
	height: 13px;
}



@media (min-width:992px) {
	.footer {
		flex-direction: row;
		padding: 32px 64px;
	}

	.footer__logo-block {
		width: 50%;
		margin-right: 16px;
		margin-bottom: 0;
	}

	.footer__link-block {
		width: 50%;
		margin-left: 16px;
		margin-bottom: 0;
		flex-direction: row;
	}

	.footer__info-block {
		margin-left: 16px;
	}

	.footer__soc-block {
		margin-right: 16px;
		flex-direction: column;
		width: 50%;
		margin-top: 0;
	}

	.footer__info-block>* {
		margin-bottom: 32px;
	}

	.footer__soc-block>* {
		margin-bottom: 32px;
	}

	.copyright__text {
		margin-left: 9px;
		position: relative;
		width: 162px;
		height: 20px;
	}

	.copyright .text-logo {
		height: 20px;
		opacity: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition-duration: 0.3s;
	}

	.copyright:hover .text-logo {
		opacity: 0;
	}

	.copyright__hover-text {
		font-weight: 500;
		font-size: 9px;
		line-height: 10px;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 45%;
		transform: translate(-50%, -50%);
		width: 129px;
		transition-duration: 0.3s;
		opacity: 0;
	}

	.copyright:hover .copyright__hover-text {
		opacity: 1;
	}
}

@media (max-width:991.9px) {
	.footer__soc-block>*:last-child {
		margin-left: 32px;
	}

	.copyright__text {
		display: flex;
		align-items: center;
	}

	.copyright {
		flex-direction: column;
		align-items: flex-start;
	}

	.copyright-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 33px;
		margin-bottom: 32px;
	}

	.copyright .text-logo {
		width: 107px;
	}

	.copyright .logo-mini {
		width: 20px;
		height: 17px;
		margin-right: 6px;
	}

	.copyright__hover-text {
		font-weight: 500;
		font-size: 6px;
		line-height: 16px;
	}
}

@media (max-width: 400px) {
	.footer__copyright {
		font-size: 12px;
	}
}