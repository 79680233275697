.header {
}

.header__wrapper {
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	border-bottom: 1px solid var(--grey);
}

.header__logo {
	width: 168px;
	height: 32px;
}

.header__switch {
	width: 24px;
	height: 24px;
	position: relative;
}

.header__switch .header__switch-icon,
.header__switch .header__switch-icon-active {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition-duration: 0.4s;
}

.header__switch .header__switch-icon {
	opacity: 1;
}

.header__switch .header__switch-icon-active {
	opacity: 0;
}

.header__switch.active .header__switch-icon {
	opacity: 0;
}

.header__switch.active .header__switch-icon-active {
	opacity: 1;
}

.header__navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.header__phone {
	display: flex;
	align-items: center;
}

.header__phone path {
	fill: var(--body-color);
	transition-duration: 0.4s;
}

.header__number {
	white-space: nowrap;
	font-size: 18px;
	line-height: calc(24/18);
	color: var(--body-color);
	margin-top: 3px;
	transition-duration: 0.4s;
}

.header button {
	font-weight: 400;
}

.header__phone:hover .header__number {
	color: var(--primary);
	transition-duration: 0.4s;
}
.header__phone:hover svg, .header__phone:hover path {
	fill: var(--primary);
	transition-duration: 0.4s;
}

.header__menu-mobile {
	transition-duration: 0.4s;
	width: 100%;
	height: 0;
	opacity: 0;
	display: flex;
	flex-direction: column;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 16px;
	padding-right: 16px;
}

.header__menu-mobile.active {
	/* transform: translateY(0); */
	opacity: 1;
	/* height: calc(100vh - 65px); */
	height: calc(var(--vh,1vh)*100 - 65px);
	padding-top: 48px;
	padding-bottom: 24px;
	
}

.header__menu-mobile-links {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.header__menu-mobile-links a {
	font-size: 18px;
	line-height: 24px;
	color: #000000 !important;
	margin-bottom: 32px;
}

.header__menu-mobile-links a:last-child {
	margin-bottom: 0;
}

.header__menu-mobile-footer {
	margin-top: auto;
	padding-top: 24px;
	border-top: 1px solid var(--grey);
}

@media (min-width:992px) {
	.header__navigation {
		width: 434px;
		max-width: 40%;
	}

	.header__wrapper {
		padding: 32px 64px;
	}
}