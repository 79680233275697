.dropdown {
	width: 100%;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 8px;
	padding-right: 48px;
	border: 1px solid var(--grey);
	cursor: pointer;
}

.dropdown svg {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
	transition-duration: 0.4s;
}

.dropdown.active svg {
	transform: scaleY(-1) translateY(50%);
}

.dropdown__title,
.dropdown__title-default {
	line-height: calc(16/20);
}

.dropdown__title-default {
	color: #6d6d6d;
}

.dropdown__list {
	position: absolute;
	top: calc(100% + 8px);
	left: -1px;
	width: calc(100% + 2px);
	display: none;
	border: 1px solid var(--grey);
	background-color: #ffffff;
	z-index: 40;
}

.dropdown__list.active {
	display: block;
}

.dropdown__list-item {
	width: 100%;
	padding: 8px;
	line-height: calc(20/16);
	border-bottom: 1px solid var(--grey);
	transition-duration: 0.2s;
}

.dropdown__list-item:hover {
	color: var(--primary);
}


.dropdown__list-item:last-child {
	border-bottom: none;
}

@media (min-width:992px) {
	.dropdown {
		width: 260px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 12px;
		padding-right: 56px;
	}

	.dropdown svg {
		right: 12px;
	}
}