.slider-thumbs {
	display: flex;
	flex-direction: row-reverse;
}

.slider-thumbs__preview {
	width: 152px;
	min-width: 152px;
}

.slider-thumbs__preview {
	overflow: visible;
	max-height: calc(100% - 112px);
}

.slider-thumbs .swiper-slide-visible {
	min-height: 83px !important;
}

.slider-thumbs__preview-wrapper {
	overflow: hidden;
	width: 152px;
	min-width: 152px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 16px;
	margin-left: 0 !important;
}

.slider-thumbs__preview .swiper-button-prev,
.slider-thumbs__preview .swiper-button-next {
	height: 40px;
	width: 100%;
	background-color: #ffffff;
	border: 2px solid var(--grey);
	left: 0 !important;
	opacity: 1 !important;
	transition-duration: 0.4s;
}

.slider-thumbs__preview .swiper-button-prev.swiper-button-disabled,
.slider-thumbs__preview .swiper-button-next.swiper-button-disabled {
	background-color: #e6e6e6;
}

.slider-thumbs__preview .swiper-button-prev {
	top: -56px;
	margin-top: 0 !important;
	bottom: auto;
}

.slider-thumbs__preview .swiper-button-prev::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	background-size: contain;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMTc1IDEyLjg0MTdMMTAgOS4wMjVMMTMuODI1IDEyLjg0MTdMMTUgMTEuNjY2N0wxMCA2LjY2NjY3TDUgMTEuNjY2N0w2LjE3NSAxMi44NDE3WiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K);
}

.slider-thumbs__preview .swiper-button-next::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	background-size: contain;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMTc1IDcuMTQ5OTlMMTAgMTAuOTc1TDEzLjgyNSA3LjE0OTk5TDE1IDguMzMzMzNMMTAgMTMuMzMzM0w1IDguMzMzMzNMNi4xNzUgNy4xNDk5OVoiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==);
}

.slider-thumbs__preview .swiper-button-next {
	bottom: -56px;
	top: auto;
}

.slider-thumbs .swiper-slide {
	cursor: pointer;
}

@media (min-width: 1200px) {
	.slider-thumbs {
		width: 100%;
		height: 100%;
	}
}

@media (max-width: 1199.9px) {
	.slider-thumbs {
		width: 100%;
		max-width: 100%;
		height: 100%;
	}
}

@media (min-width: 1433px) {

	.slider-thumbs .swiper-wrapper > *::before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-top: calc(100 / 152 * 100%);
	}

	.slider-thumbs .swiper-slide-visible::before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-top: calc(100 / 152 * 100%);
	}

	.slider-thumbs__preview-wrapper {
		width: 18%;
		min-width: 18%;
		display: flex;
		flex-direction: column;
	}

	.slider-thumbs__preview-wrapper>* {
		min-height: calc(100% - 112px);
		display: flex;
		flex-direction: column;
	}

	.slider-thumbs__preview-wrapper .swiper-wrapper {
		height: min-content;
		margin: auto 0;
	}

	.slider-thumbs__preview {
		width: 100%;
	}
}

@media (max-width: 991.9px) {
	.slider-thumbs__preview-wrapper {
		display: none;
	}
	.slider-thumbs__slider{
		overflow: visible;
		max-width: calc(100vw - 32px);
	}
	.slider-thumbs__slider .swiper-slide{
		max-width: calc(100vw - 32px);
	}
}