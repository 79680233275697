.car-page-top {
	padding: 28px 64px 40px;
}

.car-page-top .button {
	margin-bottom: 28px;
}

.car-page-top .button {
	padding-left: 0;
	--btn-span-left: 8px;
}

.car-page-top .button svg {
	position: relative;
	top: 0px;
}

.car-page-top__title-and-cost {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.car-page-top__cost, .car-page-top__title {
	font-weight: 700;
	font-size: 64px;
	line-height: 76px;
}

/* .car-page-top__title-and-cost h1 {
	font-weight: 700;
	font-size: 64px;
	line-height: 76px;
} */

.car-page-top__title {
	width: 60%;
}

.car-page-top__cost {
	white-space: nowrap;
}

.car-page-car-info {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	padding: 0 64px 88px;
}

.car-page-car-info__slider {
	width: 65%;
}

.car-page-car-info__description {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	width: 32%;
}

.car-page-car-info__description p {
	margin-bottom: 48px;
}

.car-page-car-info__position {
	padding: 16px 0;
	border-bottom: 1px solid var(--grey);
	--bs-gutter-x: 0;
}

.car-page-car-info__position:first-child {
	border-top: 1px solid var(--grey);
}

.car-page-car-info__header {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	text-transform: uppercase;
}

.car-page-car-info__data {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: end;
}

.car-page-car-info__data span {
	padding: 0 8px;
	white-space: nowrap;
	position: relative;
}

.car-page-car-info__data span:last-child {
	padding-right: 0;
}

.car-page-car-info__data span:first-child {
	padding-left: 0;
}

.car-page-car-info__data span:first-child:before {
	display: none;
}

.car-page-car-info__data span::before {
	content: "";
	height: 17px;
	width: 1px;
	background-color: #222222;
	position: absolute;
	left: 0;
	top: 0;
}

.car-page__equipment {
	padding-top: 0;
	padding-left: 64px;
	padding-right: 64px;
	padding-bottom: 64px;
}

.car-page__equipment h3 {
	margin-bottom: 40px;
}

/* @media (max-width: 1367px) {
	.car-page-top__title-and-cost {
		display: block;
	}

	.car-page-top__title-and-cost h1 {
		width: 100%;
	}

	.car-page-top__title-and-cost h1:first-child {
		margin-bottom: 24px;
	}
} */

.fill-block {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.car-page-car-info__slider {
	height: 0;
	padding-bottom: calc(480 / 848 * 65%);
	position: relative;
	width: 65%;
}

@media (max-width: 1199.9px) {
	.car-page-car-info {
		flex-direction: column;
		justify-content: space-between;
	}

	/* .fill-block {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	} */

	.car-page-car-info__slider {
		padding-bottom: calc(200 / 288 * 100%);
		width: 100%;
	}

	.car-page-car-info__description {
		font-size: 16px;
		line-height: 20px;
		margin-top: 24px;
		width: 100%;
	}

	.car-page-car-info__description p {
		margin-bottom: 24px;
	}
}

@media (min-width: 992px) {
	.car-page__equipment h3 {
		font-size: 32px;
	}
}

@media (max-width: 991.4px) {
	.car-page__equipment {
		padding-bottom: 32px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.car-page-car-info {
		padding: 0 16px 48px;
	}

	.car-page__equipment h3 {
		margin-bottom: 24px;
	}

	.car-page-top {
		padding: 22px 16px 24px;
	}

	.car-page-top .button {
		margin-bottom: 14px;
	}

	.car-page__equipment .col-lg-6:first-child .droplist:last-child {
		border-bottom: none;
	}

	.car-page-top__title-and-cost {
		display: block;
		margin-bottom: 0;
	}

	/* .car-page-top__title h1 {
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
	} */

	.car-page-top__cost {
		font-size: 32px;
		line-height: 36px;
	}
	.car-page-top__title {
		font-size: 24px;
		line-height: 28px;
	}

	.car-page-top__cost {
		font-size: 32px;
		line-height: 36px;
	}

	.car-page-top__title {
		width: 100%;
		margin-bottom: 16px;
	}

	.car-page-car-info__position {
		padding: 12px 0;
		border-bottom: 1px solid var(--grey);
	}

	.car-page-car-info__header {
		font-size: 16px;
		line-height: 20px;
	}

	.car-page-car-info__data {
		font-size: 16px;
		line-height: 20px;
	}
}
@media (max-width: 350px) {
	.engine {
		width: 88px !important;
	}
	.engine-data {
		width: 162px !important;
	}
	.engine-row {
		display: flex;
		justify-content: space-between;
	}
}
