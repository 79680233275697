.button {
	padding: 12px 24px;
	border: 2px solid var(--btn-border-color, transparent);
	color: var(--btn-color, black);
	background-color: var(--btn-bg-color, white);
	font-weight: 700;
	font-size: 18px;
	line-height: calc(24 / 18);
	transition-duration: 0.2s;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.button span {
	margin-left: var(--btn-span-left, 0px);
	margin-right: var(--btn-span-right, 0px);
	transition-duration: 0.2s;
}

.button:hover {
	border-color: var(--btn-hover-border-color, transparent);
	color: var(--btn-hover-color, black);
	background-color: var(--btn-hover-bg-color, white);
}

.button img,
.button svg,
.button path {
	width: var(--img-w, 20px);
	height: var(--img-h, 20px);
	fill: var(--btn-color);
	transition-duration: 0.2s;
}

.button:hover svg,
.button:hover path {
	fill: var(--btn-hover-color);
}

.btn-main {
	--btn-border-color: #00ac6e;
	--btn-color: #ffffff;
	--btn-bg-color: #00ac6e;
	--btn-hover-border-color: #008b59;
	--btn-hover-bg-color: #008b59;
	--btn-hover-color: #ffffff;
}

.btn-outline {
	--btn-border-color: #00ac6e;
	--btn-color: #222222;
	--btn-bg-color: transparent;
	--btn-hover-border-color: #008b59;
	--btn-hover-bg-color: transparent;
	--btn-hover-color: #008b59;
}

.btn-back {
	border: none;
	--btn-color: #222222;
	--btn-bg-color: transparent;
	--btn-hover-bg-color: transparent;
	--btn-hover-color: #00ac6e;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}
.btn-back img,
.btn-back  svg,
.btn-back  path {
	fill: var(--btn-hover-color);
}
.button.disable {
	opacity: 0.4;
	pointer-events: none;
}

@media (max-width: 991.4px) {
	.button {
		padding: 10px 16px;
		font-size: 16px;
		line-height: calc(20 / 16);
		--img-w: 15px;
		--img-h: 15px;
	}
}
/* .btn-small {
	padding: 10px 16px;
	font-size: 16px;
	line-height: calc(20/16);
	--img-w: 15px;
	--img-h: 15px;
} */

.btn-only-icon {
	padding: 14px;
}

.btn-only-icon span {
	display: none;
}

.btn-small .btn-only-icon {
	padding: 12.5px;
}

.btn-left-icon {
	--btn-span-left: 10px;
}

.btn-right-icon {
	--btn-span-right: 10px;
}

.btn-underline {
	padding: 0;
	border: none;
	--btn-color: #222222;
	--btn-bg-color: transparent;
	--btn-hover-bg-color: transparent;
	--btn-hover-color: #008b59;
	--btn-hover-border-color: #008b59;
	--btn-border-color: #00ac6e;
	position: relative;
}

.btn-underline:hover:before {
	background-color: var(--btn-hover-border-color);
	opacity: 1;
}

.btn-underline::before {
	content: "";
	position: absolute;
	bottom: -4px;
	height: 2px;
	width: 100%;
	background-color: var(--btn-border-color);
	opacity: 0;
	transition-duration: 0.2s;
}

.btn-underline.active::before {
	opacity: 1;
}

@media (max-width: 992px) {
	.button.mobile-full-width {
		width: 100%;
		justify-content: center;
	}
}
