.slider-popup {
	display: flex;
	flex-direction: row;
	max-height: calc(100vh - 64px);
}

.slider-popup .swiper-slide {
	cursor: pointer;
}

.slider-popup__preview-wrap {
	width: 8.2%;
	min-width: 80px;
	margin-right: 16px;
	overflow: hidden;
	padding-top: 56px;
	padding-bottom: 56px;
	position: relative;
	max-height: calc(100vh - 64px);
}

.slider-popup__preview {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	/* max-height: 100%; */
	max-height: calc(100vh - 176px);
}

.slider-popup__preview .swiper-slide::before {
	content: "";
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
	padding-top: calc(60 / 80 * 100%);
}

.slider-popup__preview .swiper-button-prev,
.slider-popup__preview .swiper-button-next {
	height: 40px;
	width: 100%;
	background-color: #ffffff;
	border: 2px solid var(--grey);
	left: 0 !important;
	opacity: 1 !important;
	transition-duration: 0.4s;
	display: block !important;
}

.slider-popup__preview .swiper-button-prev.swiper-button-disabled,
.slider-popup__preview .swiper-button-next.swiper-button-disabled {
	background-color: #e6e6e6;
}

.slider-popup__preview .swiper-button-prev {
	top: -56px;
	margin-top: 0 !important;
	bottom: auto;
}

.slider-popup__preview .swiper-button-prev::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	background-size: contain;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMTc1IDEyLjg0MTdMMTAgOS4wMjVMMTMuODI1IDEyLjg0MTdMMTUgMTEuNjY2N0wxMCA2LjY2NjY3TDUgMTEuNjY2N0w2LjE3NSAxMi44NDE3WiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K);
}

.slider-popup__preview .swiper-button-next::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	background-size: contain;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMTc1IDcuMTQ5OTlMMTAgMTAuOTc1TDEzLjgyNSA3LjE0OTk5TDE1IDguMzMzMzNMMTAgMTMuMzMzM0w1IDguMzMzMzNMNi4xNzUgNy4xNDk5OVoiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==);
}

.slider-popup__preview .swiper-button-next {
	bottom: -56px;
	top: auto;
}

.popup .slider-popup__slider .swiper-pagination {
	bottom: 0;
	padding: 18px;
	padding-bottom: 18px;
}

.slider-popup__slider .swiper-pagination {
	font-size: 16px;
	line-height: calc(20 / 16);
	color: #6d6d6d;
	font-weight: 500;
	background-color: #ffffff;
}

.slider-popup__slider .swiper-pagination::before {
	position: absolute;
	content: "";
	height: 1px;
	width: calc(100% - 32px);
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--grey);
}

.slider-popup__slider .swiper-button-prev,
.slider-popup__slider .swiper-button-next {
	margin-top: 0;
	top: auto;
	bottom: 18px;
	width: 20px;
	height: 20px;
	opacity: 1 !important;
	cursor: pointer;
	z-index: 20;
}

.slider-popup__slider .swiper-button-prev {
	left: 16px;
}

.slider-popup__slider .swiper-button-next {
	right: 16px;
}

.slider-popup__slider .swiper-button-prev::after,
.slider-popup__slider .swiper-button-next::after {
	content: "";
	background-size: contain;
	opacity: 1 !important;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.slider-popup__slider .swiper-button-prev::after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjg0MiAxMy44MTY3TDkuMDI1MzMgMTBMMTIuODQyIDYuMTc1TDExLjY2NyA1TDYuNjY2OTkgMTBMMTEuNjY3IDE1TDEyLjg0MiAxMy44MTY3WiIgZmlsbD0iIzZENkQ2RCIvPgo8L3N2Zz4K);
}

.slider-popup__slider .swiper-button-next::after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMTU4MiAxMy44MTY3TDEwLjk3NDkgMTBMNy4xNTgyIDYuMTc1TDguMzMzMiA1TDEzLjMzMzIgMTBMOC4zMzMyIDE1TDcuMTU4MiAxMy44MTY3WiIgZmlsbD0iIzZENkQ2RCIvPgo8L3N2Zz4K);
}

@media (min-width: 992px) {
	.swiper-popup__wrap .swiper {
		margin-left: unset;
		margin-right: unset;
		width: 100%;
		height: 100%;
	}

	.swiper-popup__wrap {
		width: 100%;
		height: 0;
		padding-top: calc(613 / 880 * 100%);
		position: relative;
	}

	.swiper-popup__wrap-inside {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.slider-popup__slider {
		flex-grow: 1;
		max-height: calc(100vh - 64px);
		overflow: visible;
	}

	.slider-popup {
		align-items: flex-start;
	}

	.slider-popup__slider .swiper-button-next,
	.slider-popup__slider .swiper-button-prev,
	.slider-popup__slider .swiper-pagination {
		display: none !important;
	}
}

@media (max-width: 991.9px) {
	.slider-popup__preview-wrap {
		display: none;
	}

	.slider-popup {
		flex-direction: column;
	}

	.slider-popup__slider {
		max-height: calc(var(--vh,1vh)*100 - 72px);
		height: calc(var(--vh,1vh)*100 - 72px);
		max-width: 100vw;
		position: relative;
	}

	.slider-popup__slider .swiper-wrapper {
		height: 75vw;
		top: var(--top, 0px);
	}
}
