.main-wrapper {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	position: relative;
}

.main-wrapper>* {
	z-index: 2;
}

.bg-image {
	position: absolute;
	width: 4947px;
	height: 5230px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
}

.bg-image img {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}