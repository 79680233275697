.main-page-header {
	padding: 48px 16px;
}

.main-page-header__title-block {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.main-page-header__title-block h1 {
	width: 100%;
}

.main-page-header__title-caption {
	width: 100%;
	margin-top: 24px;
}

.main-page-header__caption {
	margin-bottom: 24px;
}

.main-page-header__pic {
	width: 100%;
}

.main-page-header__pic img {
	width: 100%;
}

.main-page-about-company {
	padding: 48px 16px 32px;
	padding-top: 0;
}

.main-page-about-company__wrapper {
	padding-top: 48px;
	border-top: 1px solid var(--grey);
	display: flex;
	flex-direction: column;
}

.main-page-about-company__first {
	display: flex;
	flex-direction: column;
}

.main-page-about-company__first h2 {
	font-size: 64px;
	line-height: 76px;
}

.main-page-about-company__second {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.main-page-about-company h2 {
	margin-bottom: 24px;
}

.main-page-about-company__caption-first {
	margin-bottom: 32px;
}

.main-page-about-company__caption-second {
	margin-bottom: 24px;
	margin-top: 24px;
}

.main-page-about-company__pic-first,
.main-page-about-company__pic-second {
	width: 100%;
}

.main-page-about-company__pic-first img,
.main-page-about-company__pic-second img {
	width: 100%;
	max-width: 100%;
}

.main-page-services {
	padding: 0px 16px 80px;
}

.main-page-services h2 {
	font-weight: 700;
	font-size: 64px;
	line-height: 76px;
	margin-bottom: 40px;
}

@media (max-width: 991.4px) {
	.first-block .droplist:last-child {
		border-bottom: none;
	}
	.first-block .d-none,
	.second-block .d-none {
		display: block !important;
	}
	.main-page-services h2 {
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 24px;
	}
}
.main-page-services .droplist__name {
	font-weight: 700;
}

.main-page-services__body {
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;
}
@media (max-width: 991.9px) {
	.main-page-services__img {
		width: 100%;
		margin-bottom: 16px;
		height: 0;
		padding-bottom: calc(96 / 160 * 100%);
		position: relative;
	}
	
	.main-page-services__img-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	
	.main-page-services__img-wrap img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
/* .main-page-services__img {
	width: 100%;
	margin-bottom: 16px;
	height: 0;
	padding-bottom: calc(96 / 160 * 100%);
	position: relative;
}

.main-page-services__img-wrap {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.main-page-services__img-wrap img{
	width: 100%;
	height: 100%;
	object-fit: cover;
} */

.main-page-services__caption {
	font-size: 16px;
}

.main-page-services .row {
	--bs-gutter-x: 64px;
}

@media (min-width: 1400px) {
	.main-page-header__title-block h1 {
		font-size: 84px !important;
	}

	.main-page-about-company__caption-first {
		margin-bottom: 64px;
	}
}

@media (min-width: 1200px) {
	.main-page-about-company__caption-second {
		margin-bottom: 32px;
		margin-top: 44px;
	}

	.main-page-about-company__pic-first {
		flex-grow: 1;
	}

	.main-page-about-company__pic-first img {
		height: 100%;
		object-fit: cover;
	}

	.main-page-header__title-block {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.main-page-header__title-block h1 {
		width: 50%;
	}

	.main-page-header__title-caption {
		max-width: 760px;
		margin-left: 16px;
		width: 50%;
		margin-top: 0;
	}

	.main-page-header__caption {
		margin-bottom: 40px;
	}

	.main-page-about-company__wrapper {
		flex-direction: row;
	}

	.main-page-about-company__first {
		width: 58%;
	}

	.main-page-about-company__second {
		width: 42%;
		padding-left: 88px;
		padding-top: 0;
	}
}

@media (max-width: 991.9px) {
	.mobile-full-width {
		width: 100%;
	}
}

@media (min-width: 992px) {
	.main-page-services {
		padding: 0 64px 128px;
	}

	.main-page-services__body {
		flex-direction: row;
		align-items: flex-start;
		padding-bottom: 24px;
	}
	.main-page-services__img-wrap {
		width: 100%;
		height: 100%;
	}
	.main-page-services__img {
		width: 160px;
		min-width: 160px;
		height: 96px;
		margin-bottom: 0;
		margin-right: 24px;
	}
	.main-page-services__img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.main-page-services__caption {
		font-size: 18px;
	}

	.main-page-header {
		padding: 64px;
	}

	.main-page-about-company {
		padding: 64px;
		padding-top: 0;
	}

	.main-page-about-company__wrapper {
		padding-top: 64px;
	}

	.main-page-header__title-block h1 {
		font-size: 70px;
	}

	.main-page-about-company h2 {
		margin-bottom: 40px;
	}
}

@media (max-width: 1200px) and (min-width: 992px) {
	.main-page-about-company__first h2, .main-page-services h2 {
		font-size: 48px;
	}
}

@media (max-width: 991.4px) {
	.main-page-header__pic {
		height: 0;
		width: 100%;
		padding-bottom: calc(160 / 288 * 100%);
		position: relative;
		overflow: hidden;
	}

	.main-page-header__pic img {
		height: 100%;
		width: auto;
		position: absolute;
		left: -25%;
		top: 0;
	}
	.main-page-about-company__first h2 {
		font-size: 32px;
		line-height: 36px;
	}
}
