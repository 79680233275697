.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1500;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.preloader__wrap {
	max-width: 100px;
	width: 40vw;
	position: relative;
}

.preloader__wrap::before {
	content: "";
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
	padding-top: calc(38 / 45 * 100%);
}

.preloader__animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.preloader__animation-part {
	height: 100%;
	width: 12.5%;
	height: 100%;
	background-color: var(--primary);
}

.preloader__animation-part-1 {
	animation: grow 1s ease-in-out infinite;
}

.preloader__animation-part-2 {
	animation: grow 1s ease-in-out 0.15s infinite;
}

.preloader__animation-part-3 {
	animation: grow 1s ease-in-out 0.3s infinite;
}

.preloader__animation-part-4 {
	animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {
	0%,
	100% {
		-webkit-transform: scaleY(1);
		-ms-transform: scaleY(1);
		-o-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1.8);
		-ms-transform: scaleY(1.8);
		-o-transform: scaleY(1.8);
		transform: scaleY(1.8);
	}
}
