.popup {
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	background-color: #ffffff;
}

.popup.active {
	display: block;
}

.popup__wrap {
	display: flex;
	flex-direction: column-reverse;
}

.popup__slider {
	width: 100%;
}

.popup__info {
	width: 100%;
	padding: 16px;
	padding-right: 52px;
	position: relative;
	z-index: 5;
}

.popup__close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 16px;
	right: 20px;
	cursor: pointer;
}

.popup__close svg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.popup__info-price {
	font-weight: 500;
	font-size: 16px;
	line-height: calc(20/16);
}

.popup__info-head {
	display: flex;
	flex-direction: column-reverse;
}

.popup__info-name {
	line-height: calc(24/18);
	text-transform: uppercase;
	font-weight: 500;
}

.popup__info-body {
	margin-top: 16px;
}

.popup__info-item {
	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid var(--grey);
}

.popup__info-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}

.popup__info-item-title {
	color: #6d6d6d;
	font-size: 16px;
	line-height: calc(20/16);
	margin-bottom: 4px;
}

.popup .slider-popup {
	max-width: 100%;
}

.popup-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.popup-bg img{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@media (min-width:992px) {
	.popup__info {
		max-height: calc(100vh - 64px);
		overflow-y: auto;
	}
	.popup {
		padding: 32px 64px;
	}

	.popup__close {
		width: 24px;
		height: 24px;
		top: 16px;
		right: 16px;
	}

	.popup__wrap {
		flex-direction: row;
		align-items: flex-start;
	}

	.popup__slider {
		flex-grow: 1;
		margin-right: 32px;
		max-width: calc(100% - 336px);
	}

	.popup__info {
		width: 304px;
		min-width: 304px;
		padding: 32px;
		padding-right: 32px;
		padding-top: 40px;
		border: 1px solid var(--grey);
	}

	.popup__info-price {
		font-weight: 700;
		font-size: 32px;
		margin-bottom: 8px;
	}

	.popup__info-head {
		flex-direction: column;
	}
}

@media (max-width:991.9px) {
	.popup__info-name {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 100%;
		color: #6d6d6d;
		font-size: 12px;
		margin-bottom: 4px;
		font-weight: 400;
	}

	.popup__info-body {
		display: none;
	}
	.popup__slider {
		height: calc(100vh - 72px);
	}
}